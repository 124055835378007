import {
  StaticIconWrapper,
  type StaticIconWrapperSize,
  Tooltip,
} from "@app/design-system";
import styled from "styled-components";
import type { Placement } from "tippy.js";
import type { IncidentAlertLevel } from "../../../../.rest-hooks/types/incidents.yml";
import {
  getAlertLevelIcon,
  getAlertLevelLabel,
} from "../../../config/alertLevel";

const StyledAlertLevelIcon = styled.div`
  width: fit-content;
`;

interface AlertLevelIconProps {
  alertLevel: IncidentAlertLevel;
  size: StaticIconWrapperSize;
  tooltipPlacement?: Placement;
}

const AlertLevelIcon = ({
  alertLevel,
  size,
  tooltipPlacement,
}: AlertLevelIconProps) => {
  const icon = getAlertLevelIcon(alertLevel);
  const label = getAlertLevelLabel(alertLevel);

  return (
    <Tooltip delay message={label} placement={tooltipPlacement}>
      <StyledAlertLevelIcon>
        <StaticIconWrapper icon={icon} size={size} />
      </StyledAlertLevelIcon>
    </Tooltip>
  );
};

export default AlertLevelIcon;
