import {
  AlertLevelAdvice,
  AlertLevelEmergencyWarning,
  AlertLevelNotApplicable,
  AlertLevelPending,
  AlertLevelPlannedBurn,
  AlertLevelWatchAndAct,
} from "@app/design-system";
import type { IncidentAlertLevel } from "../../.rest-hooks/types/incidents.yml";

type AlertLevelKeys = IncidentAlertLevel | "Pending";

export const AlertLevelIconMap: Record<AlertLevelKeys, ReactSVGComponent> = {
  Advice: AlertLevelAdvice,
  "Emergency Warning": AlertLevelEmergencyWarning,
  Other: AlertLevelNotApplicable,
  Pending: AlertLevelPending,
  "Planned Burn": AlertLevelPlannedBurn,
  "Watch and Act": AlertLevelWatchAndAct,
} as const;

export const AlertLevelIconIdMap: Record<AlertLevelKeys, string> = {
  Advice: "alert-level-advice",
  "Emergency Warning": "alert-level-emergency-warning",
  Other: "alert-level-not-applicable",
  Pending: "alert-level-pending",
  "Planned Burn": "alert-level-planned-burn",
  "Watch and Act": "alert-level-watch-and-act",
} as const;

export function getAlertLevelIcon(
  alertLevel?: AlertLevelKeys | null,
): ReactSVGComponent {
  if (!alertLevel) return AlertLevelIconMap.Pending;

  if (alertLevel in AlertLevelIconMap) {
    return AlertLevelIconMap[alertLevel];
  }

  return AlertLevelIconMap.Other;
}

export function getAlertLevelIconName(alertLevel?: AlertLevelKeys | null) {
  if (!alertLevel) return AlertLevelIconIdMap.Pending;

  if (alertLevel in AlertLevelIconIdMap) {
    return AlertLevelIconIdMap[alertLevel];
  }

  return AlertLevelIconIdMap.Other;
}

export function getAlertLevelLabel(alertLevel?: AlertLevelKeys | null) {
  if (!alertLevel) return "Pending";

  return alertLevel;
}
