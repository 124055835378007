/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type IncidentStatus =
  (typeof IncidentStatus)[keyof typeof IncidentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncidentStatus = {
  BEINGCONTROLLED: "BEINGCONTROLLED",
  CONTAINED: "CONTAINED",
  GOING: "GOING",
  OUT: "OUT",
  OUTAMALGAMATED: "OUTAMALGAMATED",
  PATROL: "PATROL",
} as const;
