/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type IncidentAlertLevel =
  (typeof IncidentAlertLevel)[keyof typeof IncidentAlertLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncidentAlertLevel = {
  Advice: "Advice",
  Emergency_Warning: "Emergency Warning",
  Other: "Other",
  Watch_and_Act: "Watch and Act",
  Planned_Burn: "Planned Burn",
} as const;
